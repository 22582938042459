import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Illustration } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Illustration",
  "activeTab": "components",
  "componentId": "illustration",
  "description": "Illustrations are used to add visual appeal and delight to an interface."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Please visit the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/styles/illustration"
        }}>{`Illustration`}</a></strong>{` page for more information on using the Illustration component.`}</p>
    <p><strong parentName="p">{`Documentation in progress-----------------`}</strong></p>
    <h2>{`Variations`}</h2>
    <PatternExample example={<Illustration illustration={"success"} mdxType="Illustration" />} mdxType="PatternExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      